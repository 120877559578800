<template>
  <validation-provider
    :name="uuid"
    :rules="rules"
    :customMessages="$store.state.validatorMessages"
    ref="validationProvider"
    v-slot="validationState"
    mode="lazy"
    tag="div"
    :detect-input="false"
    :skip-if-empty="true"
    :disabled="validateDisabled"
  >
    <div :class="{ 'inline-label': inlineLabel }">
      <label
        v-if="label"
        @click.prevent="$refs.input.click()"
        :class="{
          'text-success': validationState.dirty && validationState.valid,
          'text-danger': validationState.dirty && !validationState.valid
        }"
      >
        <slot name="label">{{ label }}</slot>
        <span class="text-danger" v-if="required">*</span>
        <SvgIcon
          class="ms-1"
          color="current"
          v-if="table"
          icon="table"
          height="16"
        >
        </SvgIcon>
        <SvgIcon
          class="ms-1"
          color="current"
          v-if="eventTypeRequired"
          icon="box-alt"
          height="16"
        ></SvgIcon>
      </label>
      <div
        class="input-group"
        :class="{
          'form-input-block': block
        }"
      >
        <slot name="prepend"></slot>
        <component
          v-on="computedListeners"
          v-if="renderInput"
          :is="nowComponent"
          ref="input"
          :value="modifiedValue"
          :placeholder="placeholder"
          :class="classes(validationState)"
          :input-options="inputOptions(validationState)"
          :type="computedType"
          :mode="mode"
          :is-range="isRange"
          :disabled="disabled"
          :step="computedStep"
          :min="min"
          :max="max"
          :maxlength="maxLength"
          :accept="computedFileTypes"
          v-bind="$attrs"
        >
          <template v-if="nowComponent === 'textarea'">{{
            modifiedValue
          }}</template>
          <template
            v-if="nowComponent === 'vc-date-picker'"
            v-slot="{ inputValue, inputEvents }"
          >
            <div
              class="input-group"
              :class="{
                'form-input-block': block,
                'has-validation': validationState.dirty
              }"
            >
              <input
                v-if="isRange"
                :class="classes(validationState, true)"
                :value="dateRangeFormat(inputValue)"
                v-on="inputEvents.start"
                :disabled="disabled"
                readonly
              />
              <input
                v-else
                :class="classes(validationState, true)"
                :value="inputValue"
                v-on="inputEvents"
                :disabled="disabled"
              />
            </div>
          </template>
        </component>
        <slot name="append"></slot>
      </div>
      <div
        v-for="(error, key) in validationState.errors"
        class="invalid-feedback d-block"
        :key="key"
      >
        {{ error }}
      </div>
      <div v-if="type === 'file' && computedFileTypes" class="form-text">
        {{ $t("field.help.fileTypes") }}: {{ computedFileTypes }}
      </div>
      <div v-if="type === 'file' && maxSize" class="form-text">
        {{ $t("field.help.maxSize") }}: {{ maxSize }}
        {{ $t("field.help.sizeMb") }}
      </div>
      <div
        v-if="['text', 'textarea'].includes(type) && maxLength"
        class="form-text"
      >
        {{ $t("field.help.length") }}: {{ (value || "").toString().length }}/{{
          maxLength
        }}
      </div>
      <div class="form-text">
        <slot name="help">{{ help }}</slot>
      </div>
    </div>
  </validation-provider>
</template>

<script>
import i18n from "@/i18n";
import SvgIcon from "../base/SvgIcon";
import { extend, ValidationProvider } from "vee-validate";
import {
  email,
  max,
  min_value,
  max_value,
  regex,
  ext,
  mimes,
  size,
  required
} from "vee-validate/dist/rules";
import { v4 as uuidv4 } from "uuid";

extend("tel", {
  validate: value => {
    if (!value) return false;
    return value.formatted ? !!value.valid : true;
  },
  message: i18n.t("validatorMessages.tel")
});
extend("email", email);
extend("max", max);
extend("min_value", min_value);
extend("max_value", max_value);
extend("regex", regex);
extend("ext", ext);
extend("mimes", mimes);
extend("size", size);
extend("required", required);
extend("is", {
  // eslint-disable-next-line no-unused-vars
  validate: (value, { field, equal }) => {
    return value === equal;
  },
  params: ["field", "equal"],
  message: i18n.t("validatorMessages.is")
});

export default {
  name: "BaseInput",
  components: {
    SvgIcon,
    ValidationProvider
  },
  props: {
    equal: {
      required: false
    },
    help: {
      type: String
    },
    step: {
      type: Number
    },
    min: {
      type: Number
    },
    max: {
      type: Number
    },
    maxLength: {
      type: Number
    },
    maxSize: {
      type: Number
    },
    fileTypes: {
      type: Array
    },
    required: {
      type: [Boolean, Number],
      default: () => false
    },
    table: {
      type: Boolean,
      default: () => false
    },
    eventTypeRequired: {
      type: Boolean,
      default: () => false
    },
    block: {
      type: Boolean,
      default: () => false
    },
    inlineLabel: {
      type: Boolean,
      default: () => false
    },
    label: {
      type: String,
      required: false
    },
    color: {
      type: String,
      default: () => "secondary"
    },
    placeholder: {
      type: String,
      required: false
    },
    value: {
      required: false,
      default: () => null
    },
    type: {
      type: String,
      default: () => "text"
    },
    isRange: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    validateDisabled: {
      type: Boolean,
      default: () => true
    }
  },
  data: () => ({
    renderInput: true,
    uuid: uuidv4()
  }),
  computed: {
    computedListeners() {
      return {
        ...this.$listeners,
        input: this.inputHandler
      };
    },
    modifiedValue() {
      if (this.type === "file") return undefined;
      if (["date", "datetime", "time"].includes(this.type))
        return this.value ? this.value : null;
      return this.value;
    },
    rules() {
      let rules = { required: this.required };
      if (["text", "textarea"].includes(this.type)) {
        if (this.maxLength) rules = { ...rules, max: this.maxLength };
      }
      if (this.equal !== undefined) {
        rules = {
          ...rules,
          is: this.equal
        };
      }
      // if (this.field.minlength) rules = { ...rules, min: this.field.minlength };
      if (this.type === "number") {
        if (this.max) rules = { ...rules, max_value: this.max };
        if (this.min) rules = { ...rules, min_value: this.min };
      }

      if (this.type === "file") {
        if (this.validFileExt) {
          rules = {
            ...rules,
            ext: this.validFileExt
          };
        }
        if (this.validFileMimes) {
          rules = {
            ...rules,
            mimes: this.validFileMimes
          };
        }
        if (this.maxSize) {
          rules = {
            ...rules,
            size: this.maxSize * 1024
          };
        }
      }
      // if (this.field.resourcetype === "PhoneCF") {
      //   rules = {
      //     ...rules,
      //     regex: /^\((\d{3})\)\s(\d{3})\s(\d{2})-(\d{2})$/
      //   };
      // }

      if (this.type === "email") {
        rules = {
          ...rules,
          email: true
          // regex: /@(student\.)?bmstu\.ru$/
        };
      }
      if (this.type === "url") {
        rules = {
          ...rules,
          regex: /^(ftp|http|https):\/\/[^ "]+$/
        };
      }

      if (this.type === "tel") {
        rules = {
          ...rules,
          tel: true
        };
      }

      if (["date", "time", "datetime"].includes(this.type)) {
        if (this.isRange) {
          rules = {
            ...rules,
            min_value: 1
          };
        }
      }
      return rules;
    },
    computedFileTypes() {
      return this.fileTypes?.length
        ? this.fileTypes
            .filter(val => val)
            .map(val => (val.includes("/") ? val : `.${val}`))
            .join(", ")
        : null;
    },
    validFileExt() {
      let fileExt =
        this.fileTypes?.filter(val => val && !val.includes("/")) || [];
      return fileExt.length ? fileExt : null;
    },
    validFileMimes() {
      let fileMimes =
        this.fileTypes?.filter(val => val && val.includes("/")) || [];
      return fileMimes.length ? fileMimes : null;
    },
    mode() {
      if (this.type === "date") return "date";
      if (this.type === "time") return "time";
      if (this.type === "datetime") return "dateTime";
      return null;
    },
    nowComponent() {
      if (this.type === "tel") return "vue-tel-input";
      if (["date", "time", "datetime"].includes(this.type))
        return "vc-date-picker";
      if (this.type === "textarea") return "textarea";
      return "input";
    },
    computedType() {
      if (this.type === "float") {
        return "number";
      }
      return this.type;
    },
    computedStep() {
      if (this.type === "float") {
        return 0.01;
      }
      return this.step;
    }
  },
  mounted() {
    this.valueChange(this.value);
  },
  watch: {
    value(val) {
      this.valueChange(val);
    },
    equal() {
      this.$nextTick(() => this.validateNow(this.value));
    }
  },
  methods: {
    valueChange(val) {
      this.validateNow(val);
      this.inputTextArea(val);
      if (!val && ["file", "textarea"].includes(this.type)) {
        this.rerenderInput();
      }
    },
    validateNow(val) {
      if (!this.validateDisabled) {
        if (this.type === "tel") {
          const formatted = this.$refs.input.phoneObject.formatted;
          this.$refs.validationProvider.validate(
            formatted ? this.$refs.input.phoneObject : null
          );
        } else if (this.type === "file")
          this.$refs.validationProvider.validate(val && val[0] ? val[0] : null);
        else if (["date", "datetime", "time"].includes(this.type)) {
          let validatorVal = null;
          if (this.isRange) {
            if (this.modifiedValue)
              validatorVal = Object.values(this.modifiedValue);
            validatorVal = validatorVal || [];
            validatorVal = validatorVal.length;
          } else {
            validatorVal = this.modifiedValue;
          }
          this.$refs.validationProvider.validate(validatorVal);
        } else this.$refs.validationProvider.validate(val);
        this.$refs.validationProvider.setFlags({ dirty: true });
      }
    },
    updateValue(val) {
      if (this.type === "file" && !val) {
        this.$refs.input.value = null;
        this.$refs.input.files = [];
      }
      //this.$refs.validationProvider.validate(val);
      this.$emit("input", val);
    },
    validationState(validationContext) {
      // if (
      //     (this.field.user_field && this.user[this.field.user_field]) ||
      //     (!this.required && !this.value)
      // ) {
      //   return true;
      // }
      return validationContext.dirty || validationContext.validated
        ? validationContext.valid
        : null;
    },
    inputClasses(validationState) {
      let classes = {
        "form-control": true,
        "bg-light": !this.disabled,
        [`form-input-${this.color}`]: this.color,
        [this.validationState(validationState) ? "is-valid" : "is-invalid"]:
          this.validationState(validationState) !== null
      };
      return classes;
    },
    classes(validationState, bypass) {
      if (!bypass && ["date", "time", "datetime", "tel"].includes(this.type))
        return "";
      return this.inputClasses(validationState);
    },
    inputOptions(validationState) {
      if (this.type !== "tel") return null;
      return { styleClasses: this.inputClasses(validationState), type: "tel" };
    },
    inputHandler(e, e1 = null, e2 = null) {
      this.updateValue(this.emitValue(e, e1, e2));
    },
    getValidationContext(bypass) {
      if (bypass > 0) {
        return this.$refs.validationProvider.classes;
      }
      this.validationContextCount += 1;
      return null;
    },
    dateRangeFormat(dates) {
      if (!dates || !dates.start) return "";
      if (!dates.end || dates.start === dates.end) return dates.start;
      return `${dates.start} - ${dates.end}`;
    },
    // eslint-disable-next-line no-unused-vars
    emitValue(val, val1 = null, val2 = null) {
      if (this.type === "tel") {
        if (val === null) return "";
        return val;
      }
      if (this.type === "file") return this.$refs.input.files;
      if (this.nowComponent === "input") return this.$refs.input.value;
      if (this.nowComponent === "textarea") {
        return this.$refs.input.value;
      }
      return val;
    },
    rerenderInput() {
      this.renderInput = false;
      this.$nextTick(() => (this.renderInput = true));
    },
    inputTextArea(val) {
      if (this.type === "text") {
        if (val) this.$refs.input.value = val;
        else this.$refs.input.value = "";
      }
      // if (this.nowComponent === "textarea")
      // if (!this.$refs.input) return;
      // if (this.type === "file") {
      //   if (!this.value) {
      //     this.$refs.input.value = null;
      //   }
      // } else this.$refs.input.value = this.value;
    }
  }
};
</script>

<style lang="scss">
.input-group {
  & > span[type="date"],
  & > span[type="time"],
  & > span[type="datetime"] {
    flex: 1 0 0;
  }

  & > * > * {
    &:not(:first-child) {
      input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &:not(:last-of-type(div)) {
      input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  &.form-control-block {
    & > .form-control {
      flex: 1 0 0;
    }
  }
}

:not(.input-group) {
  &.form-control-block {
    & > .form-control {
      width: 100%;
    }
  }
}

.vue-tel-input {
  flex: 1 0 0;
  border: none;
  position: relative;
  display: flex;
  background: none;
  text-align: inherit;

  &:focus-within {
    box-shadow: none;
    border-color: transparent;
  }

  .vti__dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    &:hover,
    &:focus,
    &.open {
      background: none;
    }
  }

  .vti__input {
    border: $border-width solid $input-border-color;
    text-align: inherit;
    border-radius: $border-radius;
    padding-left: calc(0.75rem + 43px);

    &:disabled,
    &[readonly] {
      background: $input-disabled-bg;
    }

    &:focus {
      border-color: $input-focus-border-color;
    }

    &.is-valid {
      border-color: theme-color("success");
    }

    &.is-invalid {
      border-color: theme-color("danger");
    }
  }
}

$light-map: (
  900: -8,
  800: -6,
  700: -4,
  600: -2,
  500: 0,
  400: 10,
  300: 20,
  200: 40,
  100: 60
);

$color-names: (
  "blue": "primary",
  "gray": "secondary",
  "primary": "primary",
  "danger": "danger",
  "success": "success",
  "warning": "warning",
  "secondary": "secondary"
);

@function lighten-darken($color, $value) {
  @if $value > 0 {
    @return lighten($color, $value);
  }

  @if $value < 0 {
    @return darken($color, -$value);
  }

  @return $color;
}

.vc-container {
  @each $vc-name, $bs-name in $color-names {
    @each $var-number, $value in $light-map {
      $color-now: lighten-darken(theme-color($bs-name), $value);

      --#{$vc-name}-#{$var-number}: #{$color-now};
    }
  }

  font-family: inherit;
}
</style>
