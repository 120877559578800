var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{ref:"validationProvider",attrs:{"name":_vm.uuid,"rules":_vm.rules,"customMessages":_vm.$store.state.validatorMessages,"mode":"lazy","tag":"div","detect-input":false,"skip-if-empty":true,"disabled":_vm.validateDisabled},scopedSlots:_vm._u([{key:"default",fn:function(validationState){return [_c('div',{class:{ 'inline-label': _vm.inlineLabel }},[(_vm.label)?_c('label',{class:{
        'text-success': validationState.dirty && validationState.valid,
        'text-danger': validationState.dirty && !validationState.valid
      },on:{"click":function($event){$event.preventDefault();return _vm.$refs.input.click()}}},[_vm._t("label",[_vm._v(_vm._s(_vm.label))]),(_vm.required)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),(_vm.table)?_c('SvgIcon',{staticClass:"ms-1",attrs:{"color":"current","icon":"table","height":"16"}}):_vm._e(),(_vm.eventTypeRequired)?_c('SvgIcon',{staticClass:"ms-1",attrs:{"color":"current","icon":"box-alt","height":"16"}}):_vm._e()],2):_vm._e(),_c('div',{staticClass:"input-group",class:{
        'form-input-block': _vm.block
      }},[_vm._t("prepend"),(_vm.renderInput)?_c(_vm.nowComponent,_vm._g(_vm._b({ref:"input",tag:"component",class:_vm.classes(validationState),attrs:{"value":_vm.modifiedValue,"placeholder":_vm.placeholder,"input-options":_vm.inputOptions(validationState),"type":_vm.computedType,"mode":_vm.mode,"is-range":_vm.isRange,"disabled":_vm.disabled,"step":_vm.computedStep,"min":_vm.min,"max":_vm.max,"maxlength":_vm.maxLength,"accept":_vm.computedFileTypes},scopedSlots:_vm._u([(_vm.nowComponent === 'vc-date-picker')?{key:"default",fn:function(ref){
      var inputValue = ref.inputValue;
      var inputEvents = ref.inputEvents;
return [_c('div',{staticClass:"input-group",class:{
              'form-input-block': _vm.block,
              'has-validation': validationState.dirty
            }},[(_vm.isRange)?_c('input',_vm._g({class:_vm.classes(validationState, true),attrs:{"disabled":_vm.disabled,"readonly":""},domProps:{"value":_vm.dateRangeFormat(inputValue)}},inputEvents.start)):_c('input',_vm._g({class:_vm.classes(validationState, true),attrs:{"disabled":_vm.disabled},domProps:{"value":inputValue}},inputEvents))])]}}:null],null,true)},'component',_vm.$attrs,false),_vm.computedListeners),[(_vm.nowComponent === 'textarea')?[_vm._v(_vm._s(_vm.modifiedValue))]:_vm._e()],2):_vm._e(),_vm._t("append")],2),_vm._l((validationState.errors),function(error,key){return _c('div',{key:key,staticClass:"invalid-feedback d-block"},[_vm._v(" "+_vm._s(error)+" ")])}),(_vm.type === 'file' && _vm.computedFileTypes)?_c('div',{staticClass:"form-text"},[_vm._v(" "+_vm._s(_vm.$t("field.help.fileTypes"))+": "+_vm._s(_vm.computedFileTypes)+" ")]):_vm._e(),(_vm.type === 'file' && _vm.maxSize)?_c('div',{staticClass:"form-text"},[_vm._v(" "+_vm._s(_vm.$t("field.help.maxSize"))+": "+_vm._s(_vm.maxSize)+" "+_vm._s(_vm.$t("field.help.sizeMb"))+" ")]):_vm._e(),(['text', 'textarea'].includes(_vm.type) && _vm.maxLength)?_c('div',{staticClass:"form-text"},[_vm._v(" "+_vm._s(_vm.$t("field.help.length"))+": "+_vm._s((_vm.value || "").toString().length)+"/"+_vm._s(_vm.maxLength)+" ")]):_vm._e(),_c('div',{staticClass:"form-text"},[_vm._t("help",[_vm._v(_vm._s(_vm.help))])],2)],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }