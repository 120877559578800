export default {
  props: {
    block: {
      type: Boolean,
      default: () => false
    },
    color: {
      type: String,
      default: "secondary"
    },
    href: {
      type: String,
      required: false
    },
    type: {
      type: String,
      default: () => "button"
    },
    to: {
      required: false
    }
  },
  computed: {
    chosenComponent() {
      if (this.to) {
        return "router-link";
      }
      if (this.href) {
        return "a";
      }
      return "button";
    }
  }
};
